import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import grid from "../../scss/flexboxgrid.module.scss"
import view from "./searchbar.module.scss"

import DropdownOption from "./Dropdown"

const staticQuery = (graphql`
  query {
    siteData {
      siteData {
        siteSettings
      }
    }
    LunrIndex
    allInventoryJson {
      nodes {
        VehicleInfo {
          IsCertified
          IsNew
          Model
          Make
          BodyType
          VehicleStatus
          Year
          VIN
        }
        Banner {
          BannerContent {
          bgColor
          placement
          text
          textColor
          }
        }
      }
    }
  }
`)

export default function SearchBar({ data }) {
  const { allInventoryJson, siteData } = useStaticQuery(staticQuery);
  const allInventoryJsonClean = removeDups(allInventoryJson.nodes)
  const includeCertified = data.includeCertified
  const labelMap = {
    certified: { Certified: true,'ALL': 'all', 'not-certified':false },
    new: { New: true, 'Used & Certified': false, Used: false }
  }
  const [filters, setFilters] = useState({
   certified: "", new: "", make: "", model: "", year: "", search: ""
  })

  const [criteria, setCriteria] = useState({
   certified: {}, new: {}, make: {}, model: {}, year: {}
  })
  const siteSettings = JSON.parse(siteData.siteData.siteSettings);
  const newInventory = siteSettings.dealerInfo.newInventory;
  const usedInventory = siteSettings.dealerInfo.usedInventory;
  const homeStaticSearch = newInventory.length > 0 && usedInventory.length > 0;

  useEffect(() => {
    const _criteria = countUnique(allInventoryJson.nodes)
    setCriteria(_criteria)
  }, [allInventoryJson.nodes])

  const filtersMap = {
    "certified": (node, value) => node.VehicleInfo.IsCertified === value,
    "new": (node, value) => node.VehicleInfo.IsNew === value,
    "make": (node, value) => node.VehicleInfo.Make === value,
    "model": (node, value) => node.VehicleInfo.Model === value,
    "year": (node, value) => node.VehicleInfo.Year === Number(value),
  }
  function showIfNeeded(vehicle){
    if(filters["new"] === true ||(filters["new"] === "" && filters["certified"] === "")){
      return vehicle.IsNew;
    }else{
      return true
    }
  }

  function countUnique(vehicles) {
    const discardMap = {
      certified: {
        Certified: vehicles.filter(node => node.VehicleInfo.IsCertified === true).length
      },
      new: {
        New: vehicles.filter(node => node.VehicleInfo.IsNew === true).length,
        Used: vehicles.filter(node => node.VehicleInfo.IsNew === false).length,
        'Used & Certified': vehicles.filter(node => node.VehicleInfo.IsNew !== true).length,
      },
      model: {},
      make: {},
      year: {}
    }

    vehicles.forEach(node => {
      if (node.VehicleInfo.VehicleStatus != "_hidden" && showIfNeeded(node.VehicleInfo)) {
        //const newKey = node.VehicleInfo.IsNew ? "New" : "Used"        
        //if (discardMap["new"].hasOwnProperty(newKey)) {
        //  discardMap["new"][newKey]++
        //} else {
        //  discardMap["new"][newKey] = 1
        //}

        if (discardMap["year"].hasOwnProperty(node.VehicleInfo.Year)) {
          discardMap["year"][node.VehicleInfo.Year]++
        } else {
          discardMap["year"][node.VehicleInfo.Year] = 1
        }

        if (discardMap["model"].hasOwnProperty(node.VehicleInfo.Model)) {
          discardMap["model"][node.VehicleInfo.Model]++
        } else {
          discardMap["model"][node.VehicleInfo.Model] = 1
        }

        if (discardMap["make"].hasOwnProperty(node.VehicleInfo.Make)) {
          discardMap["make"][node.VehicleInfo.Make]++
        } else {
          discardMap["make"][node.VehicleInfo.Make] = 1
        }
      }
    })

    return discardMap
  }

  const dropValues = {
    New: allInventoryJsonClean.filter(node => node.VehicleInfo.IsNew === true).length,
    'Used & Certified': allInventoryJsonClean.filter(node => node.VehicleInfo.IsNew === false).length,
    Certified: allInventoryJsonClean.filter(node => node.VehicleInfo.IsCertified === true).length
  }
  
  const dropValues2 = {
    New: allInventoryJsonClean.filter(node => node.VehicleInfo.IsNew === true).length,
    Used: allInventoryJsonClean.filter(node => (node.VehicleInfo.IsNew === false) && (node.VehicleInfo.IsCertified === false)).length,
    Certified: allInventoryJsonClean.filter(node => node.VehicleInfo.IsCertified === true).length
  }

  function removeDups(vehicles) {
    let unique = {};
    vehicles.forEach((v) => {
      if(!unique[v.VehicleInfo.VIN]) {
        unique[v.VehicleInfo.VIN] = v;
      }
    });
    let finalVehicleInfo = []
    Object.keys(unique).map((item, i) => (
        finalVehicleInfo = finalVehicleInfo.concat(unique[item])
    ))
    return finalVehicleInfo;
  }

  function matchCriteria() {
    let matched = allInventoryJsonClean
    Object.keys(filters).forEach(type => {
      if (filters[type] !== undefined && String(filters[type]).length > 1) {
        if(filters[type] !== 'all') {
          const array = matched
          matched = array.filter(node => filtersMap[type](node, filters[type]))
        }
      }
    })

    return countUnique(matched)
  }

  function determineShowMake(){
    if (filters.new == undefined || filters.new == false || filters.make !== '')
      return false
    return true
}

  function determineShowModel(){
    if (filters.new == true || filters.make !== '')
      return false
    return true
  }

  const addFilter = ({ type, value }) => {
    if (value === 'Certified'|| value === 'not-certified' || value === 'ALL') {
      type = 'certified'
    }
    const _filters = filters
    _filters[type] = labelMap.hasOwnProperty(type) ? labelMap[type][value] : value
    setFilters({ ...filters, ..._filters })

    const _criteria = matchCriteria()
    if (value !== undefined && value.length > 1) {
      delete _criteria[type]
    }
    setCriteria({ ...criteria, ..._criteria })
  }

  const buildSearchQuery = () => {
    const query = Object.keys(filters)
      .map(key => {
        if (filters[key] !== undefined) {
          if (filters[key] === 'all' && key === 'certified') {
            return String(filters[key]).length > 1 ? escape(key) + '=' + escape(String(true)) : null; 
          } else {
            return String(filters[key]).length > 1 ? escape(key) + '=' + escape(String(filters[key])) : null;
          }
        }
      })
      .filter(element => element != null)
      .join('&');

    if (homeStaticSearch) {
      if (filters.new === false || filters.certified === true) {
        return usedInventory + '?' + query + '&fq=1';
      } else if (filters.new === "" ) {
        return newInventory + '?' + query + 'new=true' + '&fq=1';
      } else {
        return newInventory + '?' + query + '&fq=1';
      }
    } else {
      if (query.includes('certified') || query.includes('new')) {
        return '/search?=&' + query;
      } else {
        return '/search?=&new=true&' + query;
      }
    }
  };
    
  const addSearchFilter = (event) => {
    const _filters = filters
    _filters.search = event.target.value
    setFilters({ ...filters, ..._filters })
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      let query;
  
      if (homeStaticSearch) {
        // Check if the query includes "new=true"
        if (buildSearchQuery().includes("new=true")) {
          query = newInventory + `?${buildSearchQuery()}`;
        } else {
          query = usedInventory + `?${buildSearchQuery()}`;
        }
      } else {
        query = `/search/?${buildSearchQuery()}`;
      }
  
      window.open(query, "_self");
    }
  };
  
  const renderSearch = () => (
    <div className={view["filter-nsect"]}>
      <label for="searchbox" style={{display: "none"}}>Search Vehicles</label>
      <input id="searchbox" placeholder={"Type here to search"}
        onChange={addSearchFilter} onKeyDown={keyPress} />
    </div>
  )

  return (
    <>
      {/* responsive filtering */}
      {/*<div className={`${grid["col-xs-6"]} ${grid["col-sm-3"]} ${grid["col-lg"]} 
        ${view["camper-filter"]}`}>
        <div className={`${view["camper-item"]}`}>
          <div className={view["camper-cont"]}>
            <p>SHOW FILTERS</p>
          </div>
          <div className={`${view["camper-dcont"]}`}>
            <p className={view["camper-desc"]}>
              SHOW ITEMS
            </p>
          </div>
        </div>
      </div>
      /*}

      {/* non-responsive filtering */}
      <div className={`${grid["col-xs-12"]} ${view["search-bar"]}`}
        style={{ backgroundColor: data.bgOverride && data.bgColor }}>
        <div className={`${view["filter-nresp"]}`}>
          <div className={`${grid["row"]} ${grid["col-lg-10"]} ${grid["col-md-12"]}`}>
            <div className={`${grid["col-lg"]} ${grid["col-sm-6"]} ${grid["col-xs-6"]}`}>
              {includeCertified ? <div className={view["filter-nsect"]}>
                <DropdownOption type="new" placeholder="New"
                  values={dropValues} addFilter={addFilter} label={"Condition"} />
              </div> : <div className={view["filter-nsect"]}>
                <DropdownOption type="new" placeholder="New"
                  values={dropValues2} addFilter={addFilter} label={"Condition"} />
              </div>}
            </div>
            <div className={`${grid["col-lg"]} ${grid["col-sm-6"]} ${grid["col-xs-6"]} ${view["year-filter"]}`}>
              <div className={view["filter-nsect"]}>
                <DropdownOption type="year" placeholder="ALL YEARS"
                  values={criteria.year} addFilter={addFilter} label={"Year"}
                  sorter={(a, b) => (b - a)} />
              </div>
            </div>
            <div className={`${grid["col-lg"]} ${grid["col-sm-6"]} ${grid["col-xs-12"]} ${view["makes-filter"]} ${determineShowMake() ? view["hide"] : ''}` }>
              <div className={view["filter-nsect"]}>
                <DropdownOption type="make" placeholder="ALL MAKES"
                  values={criteria.make} addFilter={addFilter} label={"Makes"} />
              </div>
            </div>
            <div className={`${grid["col-lg"]} ${grid["col-sm-6"]} ${grid["col-xs-12"]} ${view["model-filter"]} ${determineShowModel() ? view["hide"] : ''} ${determineShowMake() ? view["only"] : ''}`}>
              <div className={view["filter-nsect"]}>
                <DropdownOption type="model" placeholder="ALL MODELS"
                  values={criteria.model} addFilter={addFilter} label={"Models"}
                  sorter={(a, b) => {
                    return a.localeCompare(b);
                  }} />
              </div>
            </div>
            {/* <div className={`${grid["col-lg"]} ${grid["col-sm-12"]} ${grid["col-xs-12"]}`}>
              {renderSearch()}
            </div> */}
          </div>
          <div className={`${grid["col-lg"]} ${grid["col-md-12"]} ${grid["col-xs-12"]}`}>
                <Link className={view["filter-nbutn"]}
                  to={buildSearchQuery()}>
                  SEARCH
                </Link>
          </div>
        </div>
      </div>
    </>
  )
}

SearchBar.defaultProps = {
  data: {
    bgOverride: true,
    bgColor: "#000000",
    btOverride: false,
    btcolor: "#ffffff",
    order: 4,
    visible: true
  }
} 
